import { Icon, Paper } from '@material-ui/core';
import React from 'react';
import { Options, Localization, Icons, Components } from 'material-table';
import SearchIcon from '@material-ui/icons/Search';

export const INVIS_GREEN = '#009b3e';
export const HKCA_YEAR_SELECTION_FROM = 2020;
export const HKCA_YEAR_SELECTION_FORWARD_COUNT = 1;
export const materialTableGeneralSetting: {
  options: Options;
  localization: Localization;
  icons: Icons;
  components: Components;
} = {
  options: {
    pageSize: 50,
    pageSizeOptions: [50, 100, 500],
    padding: 'dense',
    emptyRowsWhenPaging: false,
    filtering: true,
    search: false,
    filterCellStyle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    // loadingType: 'linear',
    debounceInterval: 750,
  },
  localization: {
    header: {
      actions: '',
    },
  },
  icons: {
    Filter: React.forwardRef((props, ref) => <SearchIcon ref={ref} />),
  },
  components: {
    Container: (props) => <Paper elevation={0}>{props.children}</Paper>,
  },
};
export const RoleName = {
  personal: '個人',
  parentContact: '聯絡人（家長）',
  groupContact: '聯絡人（團體）',
  coach: '教練',
};
export enum Permission {
  Admin = 'admin',
  BackEndFull = 'backEndFull',
  BackEndFinance = 'backEndFinance',
  Personal = 'personal',
  ParentContact = 'parentContact',
  GroupContact = 'groupContact',
  Coach = 'coach',
}
export enum SystemName {
  BackEnd = 'backend',
  Personal = 'personal',
  Parent = 'parent',
  Group = 'group',
  Coach = 'coach',
}
export const SystemTypeByPermission = {
  [Permission.Admin]: SystemName.BackEnd,
  [Permission.BackEndFull]: SystemName.BackEnd,
  [Permission.BackEndFinance]: SystemName.BackEnd,
  [Permission.Personal]: SystemName.Personal,
  [Permission.ParentContact]: SystemName.Parent,
  [Permission.GroupContact]: SystemName.Group,
  [Permission.Coach]: SystemName.Coach,
};
export const SystemNameInChinese = {
  [SystemName.BackEnd]: '後台系統',
  [SystemName.Personal]: '個人系統',
  [SystemName.Parent]: '家長系統',
  [SystemName.Group]: '團體系統',
  [SystemName.Coach]: '教練系統',
};
